 //_components/icons.scss 

 .small-icon{

    background-color: $base-color;
    border-radius: 5px;
    padding: 5px;

    svg{

        fill: $base-color-font;

    }

 }