/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.epit-container {
    padding: 10px 20px;
}

.slider {
    .swiper-pagination {
        display: inline-block;
        width: unset !important;
        margin: auto !important;
        left: calc(50%) !important;
        transform: translateX(-50%) scale(75%);
        background: var(--ion-color-white);
        border-radius: 10px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
    }

    .logo {
        width: 60px;
        margin: auto;
        position: relative;
        z-index: 999;
        border-radius: 100%;
        border: 2px solid var(--ion-color-white);
        height: 62px;
        background: var(--ion-color-white);
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: var(--ion-color-custom-thr-red) !important;
    }

    .swiper-pagination-bullet {
        background: var(--ion-color-medium-tint) !important;
        opacity: 1 !important;
    }
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: exo;
    src: url("/assets/fonts/Exo-VariableFont_wght.ttf");
}

@font-face {
    font-family: Yanone Kaffeesatz;
    src: url("/assets/fonts/YanoneKaffeesatz-Regular.ttf");
}

@font-face {
    font-family: Lobster;
    src: url("/assets/fonts/Lobster-Regular.ttf");
}



@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/Poppins/Poppins-SemiBold.eot');
    src: url('/assets/fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/Poppins/Poppins-Regular.eot');
    src: url('/assets/fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




body {
    font-family: exo;
}

.custom-popup {
    .modal-wrapper {
        --background: transparent;
        background: transparent !important;
    }
}

.alert.form-error {
    div {
        text-align: right;
        font-size: 12px;
        color: var(--ion-color-custom-six-red);
        margin-top: 3px;
    }
}

.error-box {
    width: 100%;
    margin: 10px auto;
    background: var(--ion-color-custom-nin-red);
    color: var(--ion-color-white);
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px 12px 00px;
    font-size: 11px;
    font-family: exo;
    font-weight: normal;

    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid var(--ion-color-white);
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid var(--ion-color-white);
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.success-box {
    /*
  width: 100%;
  padding: 10px 10px 18px 10px;
  margin: 10px auto;
  background: var(--ion-color-custom-sec-green);
  color: var(--ion-color-white);
  border-radius: 5px;
  font-size: 13px;
  text-align: center;*/
    width: 100%;
    padding: 10px 0px 12px 00px;
    margin: 10px auto;
    background: var(--ion-color-custom-sec-green);
    color: var(--ion-color-white);
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    font-family: exo;
    font-weight: normal;

    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid var(--ion-color-white);
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid var(--ion-color-white);
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.pac-logo:after {
    display: none !important;
}

.pac-container {
    background-color: var(--ion-color-sec-white);
}

.pac-item {
    padding: 5px;
}

.pointer {
    cursor: pointer;
}

.ion-toolbar {
    position: relative;
    margin-top: -25px;

    .ion-title {
        text-align: left;
        padding: 40px 20px 15px 20px !important;
        background: var(--ion-color-custom-header);
        color: var(--ion-color-white);
    }

    .large-title {
        display: block;
        font-size: 18px;
        font-weight: bold;

        ion-icon {
            font-size: 23px;
            margin-bottom: -5px;
        }
    }
}

ion-header {
    background-color: var(--ion-color-white);
}

.cart-button {
    // z-index: 99;
    // display: inline-block;
    // padding: 10px;
    // position: fixed;
    // bottom: 120px;
    // right: 20px;
    // background: var(--ion-color-custom-red);
    // color: var(--ion-color-white);
    // border-radius: 150px;
    // width: 75px;
    // height: 75px;
    // text-align: center;
    // transform: rotate(40deg);
    // border: 4px dashed var(--ion-color-white);
    // transition: 0.5s;


    z-index: 99;
    position: fixed;
    bottom: 100px;
    right: 10px;
    border-radius: 100px;
    width: calc(100% - 20px);
    height: 60px;
    text-align: center;
    transform: rotate(0deg);
    transition: 0.5s;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;

    &.cart-top {
        bottom: calc(100% - 130px);
        transform: rotate(0deg);
        display: flex;
        width: 75px;
        height: 75px;
        flex-direction: column;

        .items-count-container{
            display: none;
        }
       .goto-cart{
        display: none;
       }

       span {
        font-weight: 600;
        font-size: 10px;
        display: block;
        white-space: nowrap;
        text-align: center;
    } 
    }

    ion-icon {
        font-size: 25px !important;
        // color: var(--ion-color-white);
    }

    span {
        font-weight: 600;
        font-size: 14px;
        display: block;
        text-align: center;
    }

    &.animating{
        .items-count-container{
            display: none;
        }
       .goto-cart{
        display: none;
       }
    span {
        font-weight: 600;
        font-size: 10px;
        display: block;
        text-align: center;
    } 
    }
}
.popup-container .popup-container-inner .cart-button{
    display: none !important;
}
.cart-bg {
    display: block;
    position: absolute;
    bottom: 70px;
    content: " ";
    width: 100%;
    height: 90px;
    display: none;
    //   background-color: black;
}
.cart-button.cart-top {
    bottom: calc(100% - 130px);
    transform: rotate(0deg);
}

.cart-button.animating {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    top: unset;
    bottom: calc(50% - 25px) !important;
    right: calc(50% - 25px) !important;
    background: var(--ion-color-custom-red);
    color: var(--ion-color-white);
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(0deg);
    border: none;

    &::before {
        content: " ";
        width: 75px;
        height: 75px;
        display: block;
        position: absolute;
        left: -3px;
        top: -3px;
        border-radius: 100%;
        border: 3px dashed var(--ion-color-white);
        animation: cart-animation-1 1.5s infinite linear;
    }

    ion-icon {
        opacity: 1;
        animation: cart-animation-2 1.5s linear;
    }

    span {
        font-weight: 600;
        font-size: 12px;
        display: block;
        text-align: center;
    }
}

.red-button {
    background: var(--ion-color-custom-thr-red);
    padding: 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-white);
    min-width: 250px;
    font-size: 18px;
    ;
}

.text-center {
    text-align: center !important;
}

.red-color {
    color: var(--ion-color-custom-eig-red);
}

green-color {
    color: var(--ion-color-custom-green);
}

.ion-back-opacity {
    opacity: 0.65;
}

@keyframes cart-animation-1 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(90deg) scale(1);
    }

    100% {
        transform: rotate(180deg) scale(1);
    }
}

@keyframes cart-animation-2 {
    0% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    49.9% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    50% {
        transform: translateY(-100px) scale(1);
        opacity: 0;
    }

    75% {
        padding-bottom: 0;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    80% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }

    85% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    90% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }

    95% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    100% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

.submit-buttons {
    background: var(--ion-color-custom-fif-red);
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #f9f9f9;
    box-shadow: 0 1px 4px 1px var(--ion-color-custom-thr-gray);
    font-weight: bold;
    font-size: 12px;

    ion-icon {
        font-size: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
    }

    .text {
        font-size: 12px !important;
        text-transform: full-size-kana;
    }
}

.submit-buttons:disabled {
    background: var(--ion-color-custom-thr-gray);
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-custom-gray);
    box-shadow: 0 0px 0px 0px var(--ion-color-custom-thr-gray);
    font-weight: bold;
    font-size: 12px;
}

.sub-items {
    font-size: 13px;
    color: #79797996;
    padding-left: 5px;

    ul {
        padding-left: 5px !important;

        li {
            list-style: none;
        }
    }
}

.pb100 {
    padding-bottom: 100px;
}

// padding left 0
.pl-0 {
    padding-left: 0px !important;
}

.height-auto {
    height: auto !important;
}

.text-red {
    color: var(--ion-color-custom-red) !important;
}

.find-nearby-back-button {
    top: 7px !important;
    position: absolute !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.checkout-select {
    .alert-head {
        display: block;
        width: 100%;
        position: relative;
      //  background: var(--select-list-primary);
        background-color: var(--backend-primary-color);
        font-weight: 600;
        padding: 5px;

        h2 {
            font-size: 17px;
            //color: var(--alert-header-text-color);
            color : var(--backend-primaryText-color);
            margin: 0 !important;
            padding: 5px 0;
        }

        .alert-wrapper.sc-ion-alert-ios {
            border-radius: 5px;
        }
    }

    .alert-wrapper.sc-ion-alert-ios {
        border-radius: 5px;
    }

    .alert-message.sc-ion-alert-ios {
        padding-bottom: 0 !important;
    }

    .alert-button.sc-ion-alert-md {
        border-radius: 2px;
        position: relative;
        background-color: transparent;
        color: var(--backend-primary-color);
        font-weight: 500;
        text-align: end;
        text-transform: uppercase;
        overflow: hidden;
    }

    .alert-radio-label.sc-ion-alert-ios {
        color: var(--ion-color-custom-fou-gray);
    }

    .alert-button.sc-ion-alert-ios {
        color: var(--backend-primary-color);
        font-size: 15px;
    }

    [aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
        border-color: var(--backend-primary-color);
    }

    [aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
        color: var(--backend-primary-color);
        font-weight: bold;
        font-size: 15px;
    }
}

.m-0 {
    margin: 0px !important;
}

.float-right {
    float: right !important;
}

.web-footer {
    background-color: var(--ion-color-custom-gray);
    padding: 20px 0;

    .footer-left {
        text-align: left;

        ul {
            list-style: none;
            display: inline-block;
            margin: 0;

            li {
                display: inline;

                a {
                    color: var(--ion-color-white);
                    text-decoration: none;
                    margin: 5px;
                }
            }
        }
    }

    .footer-right {
        color: var(--ion-color-white);
        text-align: right;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.not-found {
    width: 80%;
    text-align: center;
    margin-top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 20px;
    color: var(--ion-color-custom-medium-gray);

    ion-icon {
        font-size: 40px;
    }
}

.page-bottom-padding-fix {
    padding-bottom: 200px;
}

.text-right {
    text-align: right;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;

    &.green-dot {
        background-color: var(--ion-color-custom-green);
    }

    &.orange-dot {
        background-color: var(--ion-color-custom-yellow);
    }

    &.red-dot {
        background-color: var(--ion-color-custom-eig-red);
    }
}

.text-green {
    color: var(--ion-color-custom-green);
}

.text-red {
    color: var(--ion-color-custom-eig-red);
}

.text-orange {
    color: var(--ion-color-custom-yellow);
}

.modal-wrapper {
    border-radius: 10px !important;
}

.remove-position-absolute {
    position: relative !important;
}

/*
  uncomment while build ios
ion-header{
  margin-top: 40px !important;
}
*/

.ion-spinner-red {
    color: var(--ion-color-custom-thr-red) !important;
}

.ion-spinner-white {
    color: var(--ion-color-white) !important;
}

ion-select {
    .select-icon-inner {
        left: -5px !important;
        margin-top: -7px !important;
        border-top: 9px solid !important;
        border-right: 6px solid transparent !important;
        border-left: 6px solid transparent !important;
    }
}

.main-container-for-dishes {
   
    h4{
        cursor: pointer;
        span{
            display: block;
        }
    }
    p {
        margin: 5px;
        margin-left: 10px;
        font-size: 14px;
        color: var(--ion-color-custom-fou-gray);
        position: relative;
        padding-left: 10px;

        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 4px;
            left: -3px;
            transform: translateY(-50%);
            background: var(--ion-color-fou-white);
            border-radius: 100%;
        }
    }
}

.small-spinner-inputs {
    width: 15px !important;
    height: 15px !important;
    color: var(--ion-color-custom-eig-red);
}

.no-padding {
    padding: 0px !important;
}

.custom-input-label {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.custom-input-value {
    font-size: 14px !important;
}

.pull-right {
    float: right !important;
}

.min-width-100p {
    min-width: 100% !important;
}

.header-height {
    height: 25px;
}

.reservation-success-msg {
    font-size: 12px !important;
}

.reservation-success-msg-web {
    font-size: 18px !important;
    line-height: 1.5 !important;
}

.height-300-px {
    height: 300px !important;
}

.find-my-location-btn {
    background: var(--find-my-location-btn-background);
    padding: 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-white);
    min-width: 250px;
    font-size: 18px;
    margin-top: 10px;

}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.tour-class {
    ion-backdrop {
        --backdrop-opacity: 0.8;
        pointer-events: none;
    }
}

.welcome-package-offer {
    ion-backdrop {
        --background:black;
        --backdrop-opacity: 0.8;
    }
}

.postal-item{
    margin-bottom: 5px;
    --background: #f3f3f3;
}


.subscription-order-checkbox-container{
    display: flex;
    padding: 15px 0px 15px 10px;
    flex-direction: row-reverse;
    background: var(--ion-color-fou-white);
    justify-content: space-between;
    min-height: 48px;
    font-weight: bold;
    border: 2px dashed #ec1c2c;
    border-radius: 5px;
    margin-bottom: 20px;


    &.checked{
        border: 2px dashed #b3b3b3;
    }

}


.subscription-order-alert{
    font-size: 14px;
    background-color: #09d95d75;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-top: 10px;
    color: #05651b;
}

.check-container {
    width: 5.25rem;
    height: 6.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }
  .check-container .check-background {
    width: 100%;
    height: calc(100% - 1.25rem);
    background: linear-gradient(to bottom right, #d3e55d, #64d641);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    transform: scale(0.84);
    border-radius: 50%;
    animation: animateContainer 0.75s ease-out forwards 0.75s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }
  .check-container .check-background svg {
    width: 65%;
    transform: translateY(0.25rem);
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: animateCheck 0.35s forwards 1.25s ease-out;
  }
  .check-container .check-shadow {
    bottom: calc(-15% - 5px);
    left: 0;
    border-radius: 50%;
    background: radial-gradient(closest-side, #49da83, transparent);
    animation: animateShadow 0.75s ease-out forwards 0.75s;
  }
  
  @keyframes animateContainer {
    0% {
      opacity: 0;
      transform: scale(0);
      box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    25% {
      opacity: 1;
      transform: scale(0.9);
      box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    43.75% {
      transform: scale(1.15);
      box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    62.5% {
      transform: scale(1);
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
    }
    81.25% {
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
    100% {
      opacity: 1;
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
  }
  @keyframes animateCheck {
    from {
      stroke-dashoffset: 80;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes animateShadow {
    0% {
      opacity: 0;
      width: 100%;
      height: 15%;
    }
    25% {
      opacity: 0.25;
    }
    43.75% {
      width: 40%;
      height: 7%;
      opacity: 0.35;
    }
    100% {
      width: 85%;
      height: 15%;
      opacity: 0.25;
    }
  }

  .recharge-success{
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
    font-size: 22px;
  }

  .success-amount{
    margin-top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }

  .gastro-calendar{
    .days{
        .on-selected{
            background-color: var(--calender-primary-color) !important;
            p{
                color: var(--calender-text-color) !important;
               
            }

            &.today {
                p{
                    color: var(--calender-text-color) !important;
                }
            }

        }
        .today{
            p{
                color: var(--calender-primary-color) !important;
            }
        }
    }
   }

.app-version-no {
    font-size: 12px;
    color: white;
    position: absolute;
    bottom: 2px;
    font-weight: bold;
    left: 5px;
    background: black;
    padding: 0px 3px;
    border-radius: 4px;
}

.delivery-cost-changed-toast{
    --background: var(--delivery-cost-changed-toast-bg) !important;
    --color: var(--delivery-cost-changed-toast-text) !important;
}
/*

Importing New Theme

*/



.modal-wrapper {
    ion-content {
        --padding-bottom: 0px;
    }
}


@media screen and (min-width: 992px) {

    .sc-ion-modal-md-h {
        --width: 90%;
        --height: 90vh;
        --ion-safe-area-top: 0px;
        --ion-safe-area-bottom: 0px;
        --ion-safe-area-right: 0px;
        --ion-safe-area-left: 0px;
       // max-width: 1000px !important;
    
        
    .modal-wrapper{
        max-width: 1000px !important;
    }
    
    }
    
    
    
    .order-popup{
        
    &.sc-ion-modal-md-h {
        --width: 600px;
        --height: 500px;
       // max-width: 1024px;
        --ion-safe-area-top: 0px;
        --ion-safe-area-bottom: 0px;
        --ion-safe-area-right: 0px;
        --ion-safe-area-left: 0px;
    }
    }
    
    }
    
    
    
.toping-container {
    ul {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 20px;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            border: 1px solid #d8d8d8;
            margin-bottom: 20px;
            border-radius: 10px;
            position: relative;
            padding-bottom: 20px;
            .error {
                color: #ffffff;
                font-size: 13px;
                padding: 10px 5px;
                background: #c0392b;
                --background: transparent;
                position: sticky;
                top: 0;
                margin-top: 5px;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

}

app-modal-popover {
    ion-header {
        ion-toolbar {
            ion-title {
                ion-icon {
                    background: #d8d8d8;
                    padding: 5px;
                    /* margin-bottom: -10px; */
                    /* padding-bottom: 10px; */
                    border-radius: 5px;
                    color: #888888;
                    font-size: 18px;
                }
            }
        }
    }
}

app-toppings {
    ion-list-header {
        ion-label {
            position: relative;
            padding: 10px 0;
            background: #232323;
            margin: 0 0 0px;
            color: #fff;
        }
    }
}


.custom-popup{
 
    
}


@media screen and (min-width: 992px) {

.sc-ion-modal-md-h {
    --width: 90%;
    --height: 90vh;
    --ion-safe-area-top: 0px;
    --ion-safe-area-bottom: 0px;
    --ion-safe-area-right: 0px;
    --ion-safe-area-left: 0px;
   // max-width: 1000px !important;

    
.modal-wrapper{
    max-width: 1000px !important;
}

}



.order-popup{
    
&.sc-ion-modal-md-h {
    --width: 600px;
    --height: 500px;
   // max-width: 1024px;
    --ion-safe-area-top: 0px;
    --ion-safe-area-bottom: 0px;
    --ion-safe-area-right: 0px;
    --ion-safe-area-left: 0px;
}
}

}

.icon-container{
    position: relative;
    margin-top: 5px;
    svg{
        width: 28px;
        height: 28px;
        path{
            stroke: #fff;
           // fill: #fff;
        }
    }

    .items-count-container{
        font-size: 12px;
        width: 18px;
        position: absolute;
        color: #000;
        background-color: #fff;
        border-radius: 100%;
        top: -10px;
        right: -10px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}





@import 'gastro_theme/base.scss';



.popup-container-inner {


    &.full-size {
        height: 90% !important;
        width: 100% !important;
        top: unset !important;
        bottom: 0;
        transform: unset !important;
        left: 0 !important;
        border-radius: 0;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }

}



.new-title {
    display: flex;
    background: #ffffff00;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 5px;

    .back-buttton-container {
        background-color: #00000000;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.bg-less {
            background-color: transparent;
        }

        svg {
            width: 24px;
            height: 24px;
            opacity: 0.3;
        }
    }

    .title-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: #1d1d1dc3;
        font-size: 16px;
        font-weight: 700;
        max-width: calc(100% - 96px);
        .title-container-inner{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .space {
        width: 48px;
        height: 48px;

        &.close-buttton-container {
            background-color: #00000000;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 24px;
                height: 24px;
                opacity: 0.3;
            }
        }
    }
}




.payment_select {
    bottom: 0;
    position: absolute;
    display: flex;
    height: 90% !important;
    width: 100%;
    padding: 20px;
    justify-content: center;
    align-items: flex-end;
    .new-title{
        margin-bottom: 0 !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    &.select-modal-container-parent {
        padding: 0;

        .select-modal-container {
            width: 100%;
            max-width: unset;
            height: 100%;
            display: flex;
            flex-direction: column;

            .select-modal-content {
               // height: calc(100% - 70px);
                border-radius: 0;
                max-height: unset;
                border-top: 0;
                box-shadow: none;
                background-color: rgba(255, 255, 255, 1) !important;
                flex: 1;
            }
        }

        .full-flex-title {
            flex: 1;
            padding: 0 20px;
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            font-family: 'Poppins';
            color: #1d1d1d;
        }



        .select_payment {
            max-width: unset !important;
            max-width: unset !important;
            margin: 0 20px !important;

            .select-option-cust.payment-options {
                padding: 20px 20px;
                background: transparent;
                border: 1px solid #c4c4c4;
                border-radius: 15px;
                margin-bottom: 20px;
                margin-left: -10px;
                margin-right: -10px;
                scale: 1;

                &.selected-cus {
                    border: 2px solid #1d1d1d;

                }

                .svg {
                    width: 32px;
                    height: 32px;
                    border-radius: 5px;

                    &.svg2 {

                        background: rgb(208, 167, 41);
                        padding: 0 2px;

                        path {
                            fill: white;
                        }
                    }

                    &.svg3 {

                        //   background: #aa9c26;
                        padding: 5px;
                    }
                }

                .svg4 {
                    path {
                        fill: rgb(208, 167, 41);
                    }
                }

            }


        }


    }
}

.app-version {

    &.new_app_v {
        height: 0;
    }
}


// app-toppingswizzard{
//     height: 100%;
//     &>form{
//         height: 100%;
//         &>ul{
//             height: 100%;
//             ion-slides{
//                 min-height: 100%;
//                 .swiper-wrapper{
//                     min-height: 100%;
//                     ion-slide{
//                         min-height: 60vh;
//                         display: flex;

//                     }
//                 }
//             }
//         }
//     }
// }
.wizard-container {
    // height: 100%;
    display: flex;
    flex-direction: column;

   

    .wizard-top {}

    .wizard-bottom {
        flex: 1;
        //  display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 150px;

        // min-height: 300px;
        ion-slides {
            // height: 100%;
            //display: flex;
            flex-direction: column;

            .swiper-wrapper {
                // height: 100% !important;

                .half {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //  height: 100%;
                    min-height: 50vh;

                    .half-button {
                        display: block;
                        margin-bottom: 10px;
                        padding: 20px 50px;
                        border-radius: 10px;
                    }
                }

                .padding-20 {
                    padding: 0 20px;
                }

                .checkboxgroup ion-item {
                    &.ion-item-select {
                        --background: #F7F7F7;
                        background: #F7F7F7;
                        margin: 0;
                        border-radius: 15px;
                        padding: 8px 0;
                        font-family: 'Poppins';

                        &.padding-unset {
                            --inner-padding-start: unset;
                            --padding-start: 15px;
                        }

                        .label-1,
                        .name {
                            color: #4C4C4C;
                            font-family: "Poppins";
                            font-size: 14px;
                        }

                        .label-2,
                        .price {
                            font-size: 12px;
                            color: #4C4C4C !important;
                            opacity: 0.6;
                            font-family: 'Poppins';
                            margin-top: 3px;
                        }

                        ion-checkbox {
                            --background: #EAEAEA;
                            --background-checked: #EAEAEA;
                            --border-width: 5px;
                            --size: 28px;
                            --border-color-checked: #ffffff;
                            --border-color: #EAEAEA;
                            --checkmark-width: 5px;
                            margin: 5px 10px 10px 0;
                            --border-radius: 10px;
                        }

                        ion-radio {
                            --border-radius: 100px;
                            --inner-border-radius: 100px;
                            --color: #D9D9D9;
                            --color-checked: #ffffff;
                            --inner-border-width: 0;
                            --inner-border-width: 0px !important;
                            --border-width: 5px;
                            width: 28px;
                            height: 28px;
                            margin: 0 20px 0 0;

                            &::part(container) {
                                border-width: 5px;
                                /* Change to your desired width */
                                background-color: #D9D9D9;

                                .radio-inner {
                                    display: none;
                                }
                            }

                            &::part(mark) {
                                //  display: none;
                                background-color: var(--main-color-toppings);

                                .radio-inner {
                                    display: none;
                                }
                            }

                        }

                        ion-label {
                            margin: 0;
                        }

                        .min-max {

                            position: absolute;
                            right: 10px;
                            z-index: 5;
                            display: flex;
                            align-items: center;

                            span {
                                font-size: 16px;
                                padding: 3px 8px;
                                color: #fff;
                            }

                            button {
                                color: #9f9f9f;
                                font-weight: bold;
                                background: #f2f2f2;
                                border-radius: 8px;
                                transform: translateY(0px);
                                margin: 0 3px;
                                padding: 0;
                                display: flex;
                                height: 24px;
                                svg{
                                    path{
                                        fill: var(--main-color-toppings);
                                    }
                                }
                            }
                        }


                        &.item-checkbox-checked,
                        &.item-radio-checked {

                            --background: var(--main-color-toppings);
                            background: var(--main-color-toppings);
                            margin: 0;
                            border-radius: 15px;
                            padding: 8px 0;
                            font-family: 'Poppins';

                            .label-1,
                            .name {
                                color: #fff;
                                font-family: "Poppins";
                                font-size: 14px;
                            }

                            .label-2,
                            .price {
                                font-size: 12px;
                                color: #FFFFFF !important;
                                opacity: 0.6;
                                font-family: 'Poppins';
                                margin-top: 3px;
                            }

                            ion-checkbox {
                                --background: #ffffff;
                                --background-checked: #ffffff;
                                --border-width: 5px;
                                --size: 28px;
                                --border-color-checked: #ffffff;
                                --border-color: #d9d9d9;
                                --checkmark-width: 5px;
                                margin: 5px 10px 10px 0;
                                border-radius: 5px;
                                --border-radius: 10px;
                                --checkmark-color: var(--main-color-toppings);
                            }

                            ion-radio {
                                &::part(container) {
                                    background-color: #fff;
                                }

                            }


                        }

                    }

                }
            }
        }
    }


}

@media screen and (max-width: 991px) {

    .toping-container-mobile {
        height: 80% !important;
        display: flex;
        flex-direction: column;
    }

    div.new-content.footer-box {
        background-color: #fff !important;
        padding: 15px;

        .next-previous-container {


            padding: 10px 15px;
            background: #E6E6E6;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;


            .prev-button {
                /* padding: 10px 30px; */
                background: #88888861;
                border-radius: 5px;
                color: #646464;
                font-weight: bold;
                font-family: "Poppins";
                font-size: 12px;
                letter-spacing: 1px;
                display: flex;
                align-items: center;
                grid-gap: 2px;
                grid-gap: 5px;
                /* gap: 5px; */
                cursor: pointer;
                text-align: center;
                width: 34px;
                height: 34px;
                border-radius: 100px;
                padding: 10px;
            }
        }

        .sub-btn1 {
            padding: 9px 20px 11px;
            margin-bottom: 0px;
            background: rgb(208, 167, 41);
            color: white;
            display: flex;
            gap: 5px;
            box-shadow: none;
            flex-direction: column;
            border-radius: 10px;
        }

        .qty-container-inner {
            display: flex;
            align-items: center;
            background: #D9D9D9;
            align-content: center;
            border-radius: 50px;
            height: 36px;
            color: #4C4C4C;

            .minus {
                padding: 0;
                width: unset;
                background: transparent;
                color: #fff;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                cursor: pointer;
                display: flex;
                padding-left: 15px;
            }

            .plus {
                padding: 0;
                width: unset;
                background: transparent;
                color: #fff;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                cursor: pointer;
                display: flex;
                padding-right: 15px;
            }

        }
    }

    .wizard-top {
        position: sticky;
        top: 0;
        z-index: 999;
    }
}




@media screen and (min-width: 992px) {

   

    div.new-content.footer-box {
        background-color: #fff !important;
        padding: 15px;

        .next-previous-container {


            padding: 10px 15px;
            background: #E6E6E6;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;


            .prev-button {
                /* padding: 10px 30px; */
                background: #88888861;
                border-radius: 5px;
                color: #646464;
                font-weight: bold;
                font-family: "Poppins";
                font-size: 12px;
                letter-spacing: 1px;
                display: flex;
                align-items: center;
                grid-gap: 2px;
                grid-gap: 5px;
                /* gap: 5px; */
                cursor: pointer;
                text-align: center;
                width: 34px;
                height: 34px;
                border-radius: 100px;
                padding: 10px;
            }
        }

        .sub-btn1 {
            padding: 9px 20px 11px;
            margin-bottom: 0px;
            background: rgb(208, 167, 41);
            color: white;
            display: flex;
            gap: 5px;
            box-shadow: none;
            flex-direction: column;
            border-radius: 10px;
        }

        .qty-container{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            margin-right: 20px;
        }

        .qty-container-inner {
            display: flex;
            align-items: center;
            background: #D9D9D9;
            align-content: center;
            border-radius: 50px;
            height: 36px;
            color: #4C4C4C;

            .minus {
                padding: 0;
                width: unset;
                background: transparent;
                color: #fff;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                cursor: pointer;
                display: flex;
                padding-left: 15px;
            }

            .plus {
                padding: 0;
                width: unset;
                background: transparent;
                color: #fff;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                cursor: pointer;
                display: flex;
                padding-right: 15px;
            }

        }
    }

    .wizard-top {
        position: sticky;
        top: 0;
        z-index: 999;
    }
}


@media screen and (min-width: 992px) {

    .radio-group ion-item,
    .checkboxgroup {
        text-align: left;
        
        ion-item {
        --min-height: 10px;
        --width: 33%;
        width: calc(33% - 30px) !important;
        display: inline-block;
        margin: 0 5px !important;
    }
    }
}

.new-flex.newcart .cart-list {
    .cart-header {
        background: transparent !important;
        margin-top: 10px;
    }

    .cart-bottom-left-container {
        display: flex !important;
        gap: 15px;

        .bottom-edit-delete-cotainer {

            display: flex !important;
            clear: both !important;
            background: #EEEEEE;
            border-radius: 100px;
            padding: 5px 15px;
            gap: 15px;

            .bottom-edit-delte-button {
                display: flex !important;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

        }

        .bottom-counter {
            display: flex !important;
            clear: both !important;
            background: #EEEEEE;
            border-radius: 100px;
            padding: 5px 15px;
            gap: 5px;

            .bottom-counter-icons {
                display: flex !important;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .bottom-counter-text {
                display: flex;
                font-size: 16px;
                align-items: center;
                justify-content: center;
            }
        }

    }

    .add-note-container{
        display: flex !important;
        .add-note-button{
            background-color: #eeeeee;
            height: 34px;
            display: flex;
            padding: 0 15px;
            align-items: center;
            color: #4C4C4C;
            font-family: "Poppins";
            font-weight: 600;
            font-size: 12px;
            border-radius: 100px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 130px;
            text-overflow: ellipsis;
            .add_note_inner{
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

.title, .price-text{
    font-family: "Poppins";
    font-size: 16px !important;
    color: #4C4C4C !important;
    font-weight: 600;
    
}

.toppings_list{
    margin-top: 0;
}
.toppings_list_item{
    margin-top: 3px;
    font-family: "Poppins";
    font-size: 14px;
}
    .sub{
        display: flex;
        gap: 10px;
        align-items: flex-start;
        color: #4C4C4C;
        .strong{
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 14px;
            display: flex;
            text-decoration: underline;
            padding-top: 2px;
        }
    }
}

body{
    
  //  --main-color-toppings  : rgb(123, 106, 174);
}

@media screen and (max-width: 991px) {
 
.new-topping-container.toping-container-mobile.toping-container{
    height: calc(100% - 48px) !important;

    .error{
            color: #ffffff;
            font-size: 14px;
            padding: 5px;
            background: #e74c3c;
            --background: #e74c3c;
            font-family: 'Poppins';
            padding: 5px 10px;
            margin: 10px 10px;
            border-radius: 8px; 
        }

    app-toppingswizzard{
        display: flex;
        min-height: 1px;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .parent-flex{
            flex: 1;
            overflow-y: scroll;
        }

        .parent-flex.abslt{
            flex: 1;
            overflow-y: scroll;
            position: relative;

            .second-flex{
                position: fixed;
                width: 100%;
                bottom: 0;
                left: 0;
                z-index: 9999;
            }

        }

    }
}


}



.default-toppings-add-to-cart-button{
    display: flex !important;
    flex-direction: row !important;
    padding: 15px !important;
    align-items: center;
}

.default-toppigs-footer{
.next-previous-container{
gap: 10px;
}

}


.default-container {
    // height: 100%;
    display: flex;
    flex-direction: column;


    .wizard-top {}

    .wizard-bottom {
       // background-color: red;
        flex: 1;
        //  display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 150px;

        li{
            list-style: none;
            margin-bottom: 20px;
            .checkboxgroup {
                .radio-title{
                    margin-bottom: 10px;
                }
            }
        }


            // height: 100%;
            //display: flex;
            flex-direction: column;

                .half {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //  height: 100%;
                    min-height: 50vh;

                    .half-button {
                        display: block;
                        margin-bottom: 10px;
                        padding: 20px 50px;
                        border-radius: 10px;
                    }
                }

                .padding-20 {
                    padding: 0 20px;
                }

                .checkboxgroup { 
                    
                   // background-color: yellow;
                    
                    ion-item {
                    &.ion-item-select {
                        --background: #F7F7F7;
                        background: #F7F7F7;
                        margin: 0;
                        border-radius: 15px;
                        padding: 8px 0;
                        font-family: 'Poppins';

                        &.padding-unset {
                            --inner-padding-start: unset;
                            --padding-start: 15px;
                        }

                        .label-1,
                        .name {
                            color: #4C4C4C;
                            font-family: "Poppins";
                            font-size: 14px;
                        }

                        .label-2,
                        .price {
                            font-size: 12px;
                            color: #4C4C4C !important;
                            opacity: 0.6;
                            font-family: 'Poppins';
                            margin-top: 3px;
                        }

                        ion-checkbox {
                            --background: #EAEAEA;
                            --background-checked: #EAEAEA;
                            --border-width: 5px;
                            --size: 28px;
                            --border-color-checked: #ffffff;
                            --border-color: #EAEAEA;
                            --checkmark-width: 5px;
                            margin: 5px 10px 10px 0;
                            --border-radius: 10px;
                        }

                        ion-radio {
                            --border-radius: 100px;
                            --inner-border-radius: 100px;
                            --color: #D9D9D9;
                            --color-checked: #ffffff;
                            --inner-border-width: 0;
                            --inner-border-width: 0px !important;
                            --border-width: 5px;
                            width: 28px;
                            height: 28px;
                            margin: 0 20px 0 0;

                            &::part(container) {
                                border-width: 5px;
                                /* Change to your desired width */
                                background-color: #D9D9D9;

                                .radio-inner {
                                    display: none;
                                }
                            }

                            &::part(mark) {
                                //  display: none;
                                background-color: var(--main-color-toppings);

                                .radio-inner {
                                    display: none;
                                }
                            }

                        }

                        ion-label {
                            margin: 0;
                        }

                        .min-max {

                            position: absolute;
                            right: 10px;
                            z-index: 5;
                            display: flex;
                            align-items: center;

                            span {
                                font-size: 16px;
                                padding: 3px 8px;
                                color: #fff;
                            }

                            button {
                                color: #9f9f9f;
                                font-weight: bold;
                                background: #f2f2f2;
                                border-radius: 8px;
                                transform: translateY(0px);
                                margin: 0 3px;
                                padding: 0;
                                display: flex;
                                height: 24px;
                            }
                        }


                        &.item-checkbox-checked,
                        &.item-radio-checked {

                            --background: var(--main-color-toppings);
                            background: var(--main-color-toppings);
                            margin: 0;
                            border-radius: 15px;
                            padding: 8px 0;
                            font-family: 'Poppins';

                            .label-1,
                            .name {
                                color: #fff;
                                font-family: "Poppins";
                                font-size: 14px;
                            }

                            .label-2,
                            .price {
                                font-size: 12px;
                                color: #FFFFFF !important;
                                opacity: 0.6;
                                font-family: 'Poppins';
                                margin-top: 3px;
                            }

                            ion-checkbox {
                                --background: #ffffff;
                                --background-checked: #ffffff;
                                --border-width: 5px;
                                --size: 28px;
                                --border-color-checked: #ffffff;
                                --border-color: #d9d9d9;
                                --checkmark-width: 5px;
                                margin: 5px 10px 10px 0;
                                border-radius: 5px;
                                --border-radius: 10px;
                                --checkmark-color: var(--main-color-toppings);
                            }

                            ion-radio {
                                &::part(container) {
                                    background-color: #fff;
                                }

                            }


                        }

                    }

                }
            }

            .wizzard-flex{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
            }
            
        
    }


}


@media screen and (min-width: 992px) {
   
    .wizzard-flex{
        display: block !important;
        flex-direction: column;
        gap: 5px;
}
}

