 //_checkout.scss 

 .la-input-group-1 .la-input-text .checkout-m-w-100{
    min-width: 80px;
 }


 .la-input-group-1 {
    .input-group-down-button{
        &.checkout-page{

            width: 24px;
            padding: 0;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg{
                width: 20px;
                height: 20px;
            }

        }
    }
 }

 .checkout-radio-container-parent{
    background: #00000000;
    scale: 1.0;
    transition: 0.2s;
    &.selected{
        background: #00000014;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: -10px;
        margin-right: -10px;
        scale: 1.1;
    }
 }

   
 .checkout-address-edit-icon{
    background-color: #eaeaea;
    border-radius: 10px;
    padding: 5px;
    width: 38px;
    display: flex;
    height: 38px;
    align-items: center;
    justify-content: center;

    svg{
        height: 24px;
        width: 24px;
        path{
            fill: #0000007a;
        }
    }
}

.checkout-address-edit-container{
    display: flex;
    align-items: center;
}


.address-select-dropdown{
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    color: #000000;
    font-family: 'Poppins';
    font-size: 14px;


    .select-box-inner{
        display: flex;
        align-items: center;
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}


.checkout-color-bg-containers{
    background-color: #eaeaea;
    margin-top: 10px;
    padding-top: 1px;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #cacaca;
    border-radius: 5px;
}