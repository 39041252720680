$increment: 5;
$max-value: 100; 

@for $i from 0 through $max-value / $increment {
  $value: $i * $increment;

  // Margin classes
  .m-t-#{$value} {
    margin-top: #{$value}px;
  }
  .m-r-#{$value} {
    margin-right: #{$value}px;
  }
  .m-b-#{$value} {
    margin-bottom: #{$value}px;
  }
  .m-l-#{$value} {
    margin-left: #{$value}px;
  }
  .m-x-#{$value} {
    margin-left: #{$value}px;
    margin-right: #{$value}px;
  }
  .m-y-#{$value} {
    margin-top: #{$value}px;
    margin-bottom: #{$value}px;
  }

  // Padding classes
  .p-t-#{$value} {
    padding-top: #{$value}px;
  }
  .p-r-#{$value} {
    padding-right: #{$value}px;
  }
  .p-b-#{$value} {
    padding-bottom: #{$value}px;
  }
  .p-l-#{$value} {
    padding-left: #{$value}px;
  }
  .p-x-#{$value} {
    padding-left: #{$value}px;
    padding-right: #{$value}px;
  }
  .p-y-#{$value} {
    padding-top: #{$value}px;
    padding-bottom: #{$value}px;
  }
}


.m-y-12{
  margin-top: 12px;
  margin-bottom: 12px;
}


.m-x-12{
  margin-left: 12px;
  margin-right: 12px;
}




.p-y-12{
  padding-top: 12px;
  padding-bottom: 12px;
}


.p-x-12{
  padding-left: 12px;
  padding-right: 12px;
}