.profile-header{
    margin-top: 10px;
}


.la-profile-picture{


    width: 64px;
    height: 64px;
    background-color: $base-color;
    border-radius: 10px;
    position: relative;
    flex-shrink: 0;

    .la-profile-letter{

        font-family: $font-style-1;
        font-size: 48px;
        color : $base-color-font;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-weight: 500;

    }

}

.la-profile-contrainer{
    width: 100%;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    min-width: 0;

    .la-profile-content-middle{
        height: 2px;
        background: $border-color-1;

    }

    .la-profile-container-inner{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;



        .la-profile-content-top{

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .la-profile-top-left{}
            .la-profile-top-right{

                display: flex;
                align-items: center;

            }

        }


    }

}




.profile-address-container{
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    font-family: $font-style-2;

    .profile-container-left-error{
        display: none;
    }

    &.erroractive{
        display: flex;
        flex-direction: column;
        .profile-container-left{
            display: none;
        }
        .profile-container-right{
            border-top-right-radius: 0px;
            border-bottom-right-radius: 10px;

            .cancel-icon-container{
                background: #4caf505c;
                padding: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                svg{
                    path{
                        fill: #4caf50;
                    }
                }
            }


            .error-icon-container{
                .error-icon{
                    display: none;
                }
                .tick-icon{
                    display: inline-block;
                }
            }

        }
        .profile-container-left-error{
            display: block;
            padding: 30px 15px 30px 15px;
            text-align: center;
            font-family: $font-style-2;
            font-weight: bold;
            color: red;
            font-size: 14px;
            border-bottom: 1px solid #cecece;
        }
    }


    &:last-child{
        margin-bottom: 10px;
    }


    .profile-container-left{
        padding: 15px 15px;
        opacity: 0.6;
        font-weight: 600;
        font-size: 14px;
    }


    .profile-container-right{

        display: flex;
        align-items: center;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        justify-content: flex-end;



        .cancel-icon-container{
            display: none;
        }

        .error-icon-container{


            .error-icon{
                display: inline-block;
            }
            

            
            .tick-icon{
                display: none;
            }
            
            background: #ff000021;
            padding: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            border-bottom-right-radius: 10px;


                svg{
                    path{
                        fill: red;
                    }
                }

        }

    }

}