 //_login.scss 
.login-with-socialmedia-container{
    

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;

    .inner-socialmedia-inner{
        background-color: $border-color-1;
        width: 45px;
        height: 50px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
    }

}