:root {
    --default-color : #D9D9D9;
    --default-link-color : rgba(0, 0, 0, 0.56);
    --default-icon-color : var(--backend-primary-color);
} 


$base-color : #000;
$base-color-font : #fff;

$secondory-color-font : #000;


$border-color-1 : #EAEAEA;
$border-color-2 : rgba(0,0,0,0.14);


$sucess-color : #3ace94;
$sucess-link-color : #fff;

$default-color : var(--default-color);
$default-link-color : var(--default-link-color);
$warning-color : #ffc409;
$warning-link-color : #fff;

$default-color : #D9D9D9;
$default-link-color : rgba(0, 0, 0, 0.56);


$error-bg-color : #f19898;
$error-text-color : #990000;

$error-link-color : #fe3966;

$notice-bg-color : #E4E6FA;
$notice-text-color : $default-link-color;


$submit-background-color: $sucess-color;
$submit-text-color: $sucess-link-color;



$default-input-background : $border-color-1;
$default-input-text : rgba(0,0,0,0.3);


$font-style-1 : 'exo';
$font-style-2 : 'poppins';


$popup-background-1  : rgba(255,255,255,0.9);
$popup-title-bg-1 : #d9d9d9;


$backed-primary-color : var(--backend-primary-color);

$font-sm : 12px;
$font-md : 14px;
$font-lg : 16px;
$font-xl : 18px;
$font-xxl : 20px;
$font-xxl2 : 22px;