/* Default content for colors.scss */ 


.la-default-link{
    color: $default-link-color;
}


.la-color-1{
    color: $default-input-text;
}


.la-light-gray{
    color: $default-input-text;
}