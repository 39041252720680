 //_components/inputgroup.scss 

 .la-input-group-1{


    border-bottom: 1px solid $border-color-2;
    padding : 15px 10px;


    &:nth-child(1){
        border-top: 1px solid $border-color-2;
    }


    
    &.no-padding{
        padding: 0;
    }

    
    &.no-border{
        border: 0;
    }



    .la-input-top{
        width: 100%;
        display: flex;
        align-items: center;
    }


    .la-input-icon{
        display: flex;
    }


    &.icon-with-bg{

        
        .la-input-icon{
            
            padding: 5px;
            background: var(--default-icon-color);
            border-radius: 5px;


            svg{
                path{
                    fill-opacity : 1;
                    fill: #fff;
                }
            }


        }

    
    }

    .la-input-text{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 24px);
        align-items: center;
        min-width: 0;
        &.full-width{
            width: 100%;
        }


    }


    .la-input-text-left{
        min-width: 60px;
    }
    .la-input-text-middle{
        padding: 0 10px 0 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
    }


    .la-dialog-box{
        position: relative;
        background-color: #EAEAEA;
        border-radius: 10px;
        margin-top: 15px;
        padding: 10px 20px;
        max-height: 300px;
        transition: 0.5s;
        &.max-height-unset{
            max-height: unset;
            }
        &.la-dialog-box-hidden{
            overflow: hidden;
            margin-top: 0;
            padding: 0 20px;
            max-height: 0;
        }

        &:before{
            display: block;
            position: absolute;
            left: 40px;
            top: -8px;
            content: "";
            background-color: #eaeaea;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
        }

    }



    .input-group-close-button{

        transition: background-color 0.5s;

        &::after, &::before{
            transition: transform 0.5s;
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 1px;
            background-color: #EAEAEA;
        }


        &.active{

        color: transparent !important;
        width: 20px;
        position: relative;
        background-color: transparent !important;
        height: 1px;
        opacity: 0.7;
        &::after{
            height: 4px;
            width: 20px;
            border-radius: 100px;
            background: #f03f3c !important;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(45deg);
        }

        &::before{
            height: 4px;
            width: 20px;
            border-radius: 100px;
            background: #f03f3c !important;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(-45deg);
        }


    }

    }  


 }


 .la-input-group-2{

   display: block;
   position: relative;


   .la-input-icon-dashboard{
    display: inline-block;
    height: 38px;
    position: absolute;
    top: 5px;
    right: 10px;


    .la-input-icon-dashoard-inner{
        height: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;

        svg{
            width: 24px;
            height: 24px;
        }

    }

    .error-icon{

        width: 24px;
        height: 24px;
        content: " ";
        background-color: transparent;
        display: inline-block;
        animation: small-error-spinner 2s infinite;
    }

   }

        .la-input-2-container{

            position: relative;

        }



   input{
    position: relative;

   

   }

 }


 @keyframes small-error-spinner {
    0%{transform: rotate(45deg);}
    100%{transform: rotate(405deg);}
 }

 
 .icon-size-18{
    svg{
    width: 18px;
    height: 18px;
    } 
}




.width54.la-input-group-1 .la-input-text {
    width: calc(100% - 54px);
}


.la-input-group-login.icon-with-bg{
    .la-input-top{
        .la-input-icon{
            svg{
                position: relative !important;
            }
        }
    }
}