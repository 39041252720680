 //_components/forms.scss 


 .la-input-1{
    color: rgba(0, 0, 0, 0.56);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    border: 2px solid #BEBEBE;
    background: #fff;
    width: 100%;
    padding: 12px 15px;
 }

 .submit-button-1{
    background-color: $submit-background-color;
    color: $submit-text-color;
    height: 100%;


    &.full{
        width: 100%;
    }

 }



 .text-area1{
   width: 100%;
   height: 80px;
   background: #eaeaea;
   border: 1px solid #989898;
   border-radius: 10px;
   padding: 10px;
 }