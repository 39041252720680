 //_components/selectModal.scss 

$border : 1px solid rgba(0, 0, 0, 0.12);
 
 .select-modal{
    --background: rgba(0,0,0,0.1);
    backdrop-filter: blur(5px);
 }

 .select-modal-container-parent{

    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px;
    align-content: center;
    justify-content: center;
    align-items: center;

    .checkout-container .select-option-cust{
        display: flex;
        justify-content: space-between;

        span:first-child{
            white-space: nowrap;
            width: calc(100% - 30px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .select-modal-container{

        max-width: 350px;
        // max-height: 100%;
        min-height: 200px;
        width: calc(100% - 40px);

        .select-modal-content{

            background-color: $popup-background-1;
            border-radius: 20px 20px 0 0;
            padding: 20px;
            min-height: 200px;

            max-height: 80vh;
            overflow-y: auto;
            border: $border;
            border-bottom: none;

        }
        .select-modal-footer{
            
            border-radius: 0 0 20px 20px;
            min-height: 50px;
            display: flex;
        }

        .select-modal-footer-center{
           display: inline-block;
           padding: 5px 5px 0 5px;


           .select-modal-footer-inner{
                display: flex;
                height: 100%;
                gap : 5px;
           }


        }


        .select-modal-footer-coners{

            position: absolute;
            height: 10px;
            width: 10px;

        }

        .select-modal-footer-left{
            height: 38px;
            background-color: $popup-background-1;
            flex: 1;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 5px;
            position : relative;
            border-left: $border;
            border-bottom: $border;


            .select-modal-footer-coners{
                right: -10px;
                top: -6px;
                opacity: 0.7;
            }

        }


        .select-modal-footer-right{
            height: 38px;
            background-color: $popup-background-1;
            flex: 1;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 5px;
            position: relative;

            border-right: $border;
            border-bottom: $border;


            .select-modal-footer-coners{
                left: -10px;
                top: -6px;
                opacity: 0.7;
            }

        }

    }


    .select-buttons {
        border-radius: 10px;
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        align-items: center;
        font-weight: 500;
    }

    .select-cancel-button{
        color : $error-link-color;
        background-color: $popup-background-1;
    }

    .select-accept-button{
        color : #000;
        background-color: $popup-background-1;
        border: $border; 
        font-size: 16px;
        font-weight: bold;
    font-family: 'Poppins';
    }



    .selectbar-selected{
        background-color: #00000021;
        border-radius: 5px;
        padding: 5px 0px;
    }



    .select-icon{
        height: 25px;
        position: relative;
        width: 35px;


        .radio-select-group-2 {
            scale: 0.9;
            position: absolute;
        }

    }




    .modal-header{
        background-color: $popup-title-bg-1;
        margin-top: -20px;
        margin-left: -20px;
        margin-right: -20px;
        position: sticky;
        padding: 20px 30px;
        border-bottom: 1px solid #c9c9c9;
        top: -20px;
        left: 0;
        z-index: 9;
    }



    .address-select-box{
        background-color: $default-input-background;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid $default-input-background;
        transition: 0.3s;
        scale: 1;
        &.selected {
            border: 2px solid #3ace94;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .modal-close{
        position: absolute;
        right: 10px;
        top: 8px;
        z-index: 99;
        cursor: pointer;
    }

 }

 .select-modal-container-parent-calendar{

    display: flex;
    height: 100%;
    width: 100%;
    // padding: 20px;
    align-content: center;
    justify-content: center;
    align-items: center;

    .select-modal-container{

        max-width: 350px;
        max-height: 100%;
        min-height: 200px;
        width: calc(100% - 40px);

        .select-modal-content{

            background-color: $popup-background-1;
            border-radius: 20px 20px 0 0;
            // padding: 20px;
            min-height: 200px;

            max-height: 80vh;
            overflow-y: auto;
            border: $border;
            border-bottom: none;

        }
        .select-modal-footer{
            
            border-radius: 0 0 20px 20px;
            min-height: 50px;
            display: flex;
        }

        .select-modal-footer-center{
           display: inline-block;
           padding: 5px 5px 0 5px;


           .select-modal-footer-inner{
                display: flex;
                height: 100%;
                gap : 5px;
           }


        }


        .select-modal-footer-coners{

            position: absolute;
            height: 10px;
            width: 10px;

        }

        .select-modal-footer-left{
            height: 38px;
            background-color: $popup-background-1;
            flex: 1;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 5px;
            position : relative;
            border-left: $border;
            border-bottom: $border;


            .select-modal-footer-coners{
                right: -10px;
                top: -6px;
                opacity: 0.7;
            }

        }


        .select-modal-footer-right{
            height: 38px;
            background-color: $popup-background-1;
            flex: 1;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 5px;
            position: relative;

            border-right: $border;
            border-bottom: $border;


            .select-modal-footer-coners{
                left: -10px;
                top: -6px;
                opacity: 0.7;
            }

        }

    }


    .select-buttons {
        border-radius: 10px;
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        align-items: center;
        font-weight: 500;
    }

    .select-cancel-button{
        color : $error-link-color;
        background-color: $popup-background-1;
    }

    .select-accept-button{
        color : #000;
        background-color: $popup-background-1;
        border: $border; 
        font-size: 16px;
        font-weight: bold;
    font-family: 'Poppins';
    }



    .selectbar-selected{
        background-color: #00000021;
        border-radius: 5px;
        padding: 5px 0px;
    }



    .select-icon{
        height: 25px;
        position: relative;
        width: 35px;


        .radio-select-group-2 {
            scale: 0.9;
            position: absolute;
        }

    }




    .modal-header{
        background-color: $popup-title-bg-1;
        margin-top: -20px;
        margin-left: -20px;
        margin-right: -20px;
        position: sticky;
        padding: 20px 30px;
        border-bottom: 1px solid #c9c9c9;
        top: -20px;
        left: 0;
        z-index: 9;
    }



    .address-select-box{
        background-color: $default-input-background;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid $default-input-background;
        transition: 0.3s;
        scale: 1;
        &.selected {
            border: 2px solid #3ace94;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .modal-close{
        position: absolute;
        right: 10px;
        top: 8px;
        z-index: 99;
        cursor: pointer;
    }

 }

