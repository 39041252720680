 //_animations/button.scss 


 .animation_button_click1{

   

    &:focus{
        animation: animation_button_1 200ms;
    }

 }

 @keyframes animation_button_1 {
    0%{
        scale: 0.9;
    }

    100%{
        scale: 1;
    }
 }