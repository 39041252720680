 //_order_summery.scss 


 .la-order-summery-container{


    margin-top: 20px;

    .la-order-summery-spinner{

        margin-bottom: 10px;

    }


    .la-input-group-1 .la-input-text-left {
        min-width: 100px;
    }


    .order-summery-dish-content{
        .order-summery-dish-subtitle{

        }
        .order-summery-dish-subcount{

        }

        .order-summery-min-width {
            width: 40px;
        }

        .order-summery-min-width-60 {
            width: 60px;
        }

    }


    .input-net-amount-width{
        width: 100%;
    }

 }


 .order-summery-completed{
    .order-completed-top{
        font-size: 20px;
    }

    .completed-text{
        font-weight: 600;
        color: var(--default-link-color);
    }

    .order-dilivary-time-date{
        font-size: 14px;
        font-weight: 600;
        color: $default-input-text;
    }
 }

 .order-summery-reviews-container{
    padding: 0 10px;

    .order-summery-reviews-inner{
        padding: 10px;
    background: #e4e4e48a;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    .top-text{
        font-weight: 600;
    }
    .la-input-group-1{
        padding: 0 !important;
        border: none !important;

        .top{
            color: var(--default-link-color);
            font-size: 18px;
        }
    }
    ion-icon.pointer{
        font-size: 20px;
    }


    textarea{
        width: 100%;
        border-radius: 5px;
        border: 1px solid #00000033;
        padding: 10px;
    }

    }

 }