/* Default content for typography.scss */ 
.discription-1{
    font-family: $font-style-2;
    font-size: 14px;
}


.la-text-1{
    color: $default-link-color;
    font-family: $font-style-2;
    font-size: $font-md;
}

.text-right{
    text-align: right;
}



.text-left{
    text-align: left;
}


.f-w-600{
    font-weight: 600;
}


.font-family-1 {
    font-family: $font-style-1;
}

.font-family-2 {
    font-family: $font-style-2;
}

.font-size-sm{
    font-size: $font-sm;
}


.font-size-md{
    font-size: $font-md;
}



.font-size-lg{
    font-size: $font-lg;
}


.font-size-xl{
    font-size: $font-xl;
}
